import {pick} from '../helpers/fn'


export const getCurrentTime = () => {
    const today = new Date();
    const date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
    const time = today.getHours() + ":" + today.getMinutes();
    return date+' '+time;
}


export const getCategories = (ids) => {

    const categories = {
        1: { name:"Велоинфраструктура", img:"bikes.png" },
        2: { name:"Гаражи и парковки", img:"parkings.png" },
        3: { name:"Городское озеленение", img:"urban-landscaping.png" },
        4: { name:"Детские площадки", img:"playgrounds.png" },
        5: { name:"Домашние животные", img:"pets.png" },
        6: { name:"Дороги", img:"roads.png" },
        7: { name:"Доступная среда", img:"accessability.png" },
        8: { name:"Другое", img:"other.png" },
        9: { name:"Зеленые пространства", img:"green-spaces.png" },
        10: { name:"Инженерная инфраструктура", img:"engineering-infrastructure.png" },
        11: { name:"Кафе и магазины", img:"shops-n-food.png" },
        12: { name:"Музеи", img:"museums.png" },
        13: { name:"Мусор и Загрязнение", img:"waste.png" },
        14: { name:"Навигация", img:"navigation.png" },
        15: { name:"Наследие", img:"heritage.png" },
        16: { name:"Образование", img:"education.png" },
        17: { name:"Освещение", img:"lighting.png" },
        18: { name:"Пешеходная инфраструктура", img:"pedestrian.png" },
        19: { name:"Площадки для отдыха", img:"recreation.png" },
        20: { name:"Реклама и брендинг", img:"branding.png" },
        21: { name:"Социальная инфраструктура", img:"social.png" },
        22: { name:"Спорт", img:"sports.png" },
        23: { name:"Строительство", img:"construction-sites.png" },
        24: { name:"Транспорт", img:"transport.png" },
        25: { name:"Туризм", img:"tourism.png" },
        26: { name:"Услуги и сервисы", img:"services.png" },
        27: { name:"Фасады", img:"facades.png" }
    };
    if (ids && Array.isArray(ids)) {
        let res = [];
        ids.forEach(e => res.push(categories[e]));
        return res;
    }
}

export const makeViewState = (data) => {
    return  {
        longitude: data.lng,
        latitude: data.lat,
        zoom: data.zoom,
        minZoom:14,
        maxZoom:17,
        pitch: 0,
        bearing: 0
      };
};

export const ideaToGeojsonFeature = (data) => {
    return {
        "type": "Feature",
        "properties": {
            "id": data.id,
            "username": data.username,
            "message": data.message,
            "name": "Идея от " + data.username,
            "category": data.category,
            "date": data.date
        },
        "geometry": {
          "type": "Point",
          "coordinates": [
            data.location.lng,
            data.location.lat
          ]
        }
      }
};

export const ideasToGeojson = (data) => {
    return {
        "type": "FeatureCollection",
        "features": data.filter((item) => {
            return pick('location.lng', item, 0) * pick('location.lat', item, 0) > 0
        }).map((e) => ideaToGeojsonFeature(e))
    }
};
