import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { Form } from "antd";

import { handleForm, handleRules, handleHelp } from "./utils";

const Container = styled.div`
  .ant {
    &-input {
      font-size: 1rem;
    }

    &-form-item {
      &-label > label {
        font-size: 1rem;
        line-height: 1.375;
      }

      &-control {
        align-self: flex-start;
        width: 100%;
      }

      &-explain,
      &-extra {
        font-size: 1rem;
      }

      &-explain {
        padding: 1rem 0 1.5rem;
      }

      &-extra {
        order: -1;
        padding-bottom: 1rem;
      }
    }
  }
`;

const FormItem = styled(Form.Item)`
  margin-bottom: 2.5rem;

  &.ant-form-item-has-error {
    margin-bottom: 0;
  }
`;

const Page = ({ fields }) => {
  const { page } = useSelector(({ app }) => app);
  const location = page?.location?.map;
  const plainTypes = ["text", "textarea", "email"];

  return (
    <Container>
      {fields.map((field, i) => {
        const {
          text: label,
          node: name,
          uischema: { uiwidget, help },
          schema: { type },
        } = field;

        return (
          <FormItem
            {...{ label, name }}
            extra={help || handleHelp(uiwidget)}
            key={i}
            required
            rules={handleRules(type, uiwidget)}
            hasFeedback={plainTypes.includes(uiwidget)}
          >
            {handleForm(field, location)}
          </FormItem>
        );
      })}
    </Container>
  );
};

export default Page;
