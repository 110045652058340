import React from "react";
import styled from "styled-components";

import Section from "./section";

const Container = styled(Section)`
  > div {
    padding-top: 4.5rem;
  }

  h2 {
    margin: 0 0 2.5rem;
    font-size: 4.5rem;
    line-height: 1.04;
    letter-spacing: 0.04em;
    text-transform: uppercase;

    @media screen and (max-width: 640px) {
      font-size: 3rem;
      margin-bottom: 1.5rem;
    }
  }
`;

const About = () => (
  <Container id="about">
    <h2>О проекте</h2>
    <p>
      КБ Стрелка совместно с ВЭБ.РФ запускает совместную кампанию по вовлечению
      городов России в оценку состояния городской экономики и выработку
      актуальных мер ее долгосрочной поддержки. Опрос представителей
      муниципальных органов исполнительной власти позволит решить следующие
      задачи:
    </p>
  </Container>
);

export default About;
