import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { project } from "../config";
import { getAppData } from "../actions/app";
import Footer from "../components/footer";
import Header from "../components/header";
import Meta from "../components/meta";
import First from "../components/sections/first";
import About from "../components/sections/about";
import Cards from "../components/sections/cards";
import Description from "../components/sections/description";
import { updateProjectName } from "../actions";

const Main = () => {
  const { page } = useSelector(({ app }) => app);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAppData(project));
    dispatch(updateProjectName(project));
  }, [dispatch]);

  return (
    <>
      <Meta {...{ page }} />
      <Header fixed />
      <main>
        <First {...{ page }} />
        <About {...{ page }} />
        <Cards />
        <Description />
      </main>
      <Footer />
    </>
  );
};

export default Main;
