import React from "react";
import { DatePicker, Input } from "antd";
import locale from "antd/es/date-picker/locale/ru_RU";

import ControlGroup from "./control-group";
import SelectControl from "./select-control";
import Range from "./range";
import PhotoUpload from "../feedback/idea-form/photo-upload";

const handleAttachment = (type, data) => {
  switch (type) {
    case "variant":
      return {
        variant: data,
      };
    case "variants":
      return {
        variants: data,
      };
    case "location":
      const { lng, lat } = data ?? {};

      return {
        location: {
          lng,
          lat,
        },
      };
    case "photo":
      return {
        photo: data?.map(({ thumbUrl: url }) => ({ url })),
      };
    case "date":
      return {
        date: data?.format(),
      };
    case "value":
    case "range":
      return {
        value: data,
      };
    default:
      return {};
  }
};

const handleFormData = (data, bot, localId, nodes) => {
  const { email, name } = data;
  const user = {
    id: localId,
    username: name || localId,
    email: email || localId,
  };
  const filteredKeys = Object.keys(data).filter((key) => data[key]);
  const payload = filteredKeys.map((key) => {
    const [nodeKey, otherKey] = key.split("_");
    const filteredNodes = nodes
      ? nodes.filter(({ node }) => node === nodeKey)
      : [];
    const [{ node, type: nodeType, tags }] =
      filteredNodes?.length > 0 ? filteredNodes : [{}];
    const type = otherKey ? "plain" : nodeType;
    const ordering =
      filteredKeys.sort((a, b) => a.includes(b) - b.includes(a)).indexOf(key) +
      1;
    const params = otherKey ? ["variant", otherKey] : [type, data[nodeKey]];

    return {
      ordering,
      ts: Date.now(),
      is_answer: true,
      text: type === "plain" ? data[key] : "",
      attachment: data[key] ? handleAttachment(...params) : {},
      node,
      type,
      tags,
    };
  });

  return {
    ts: Date.now(),
    bot,
    user,
    payload,
  };
};

const handleFormSteps = (sections, nodes) =>
  sections
    .filter(({ uischema }) => uischema?.uiorder?.length > 0)
    .map(({ uischema, name }, i) => {
      const { uiorder } = uischema;
      const fields = nodes
        ? nodes
            .filter(({ node }) => uiorder?.includes(node))
            .sort((a, b) => uiorder.indexOf(a.node) - uiorder.indexOf(b.node))
        : [];

      return {
        title: name || `Шаг ${i + 1}`,
        fields,
      };
    });

const handleInitialValues = (values) => {
  const radioArray = values
    ? values
        .filter(({ uischema }) => uischema?.uiwidget === "radio")
        .map(({ node, answers }) => ({ [node]: answers?.[0]?.value }))
    : [];

  return Object.assign({}, ...radioArray);
};

const handleForm = (field) => {
  const { node: name, uischema, answers, schema } = field;
  const { uiwidget: type } = uischema;
  const { minimum: min, maximum: max } = schema;

  const handleOptions = (options) => {
    const sortedOptions = options?.sort((a, b) => a?.ordering - b?.ordering);
    const filterArr = (options, compare) =>
      options.filter(({ type }) => compare(type, "plain"));

    return [
      ...filterArr(sortedOptions, (a, b) => a !== b),
      ...filterArr(sortedOptions, (a, b) => a === b),
    ];
  };

  const options = handleOptions(answers);

  switch (type) {
    case "textarea":
      return <Input.TextArea autoSize={{ minRows: 3 }} {...{ name }} />;
    case "checkbox":
    case "radio":
      return <ControlGroup {...{ name, type, options, schema }} />;
    case "select":
      return <SelectControl {...{ name, options }} />;
    case "value":
    case "range":
      return <Range {...{ name, type, min, max }} />;
    case "date":
      return <DatePicker {...{ locale }} />;
    case "photos":
      return <PhotoUpload {...{ name }} />;
    default:
      return <Input placeholder="Введите ответ" {...{ name }} />;
  }
};

const handleRules = (type, uiwidget) => {
  switch (uiwidget) {
    case "email":
      return [
        {
          type: "email",
          required: true,
          message: "Введите корректный email!",
        },
      ];
    default:
      return [
        {
          type,
          message: "Недопустимое значение поля",
        },
        { required: true },
      ];
  }
};

const handleHelp = (uiwidget) => {
  switch (uiwidget) {
    case "radio":
      return "Выберите ответ";
    case "checkbox":
      return "Выберите ответы (можно несколько)";
    default:
      return false;
  }
};

export {
  handleFormData,
  handleFormSteps,
  handleInitialValues,
  handleForm,
  handleRules,
  handleHelp,
};
