import React from "react";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1.5rem;
  background: #fff;
  border: 0.0625rem solid #e8eef1;
  transition: all 0.3s ease-in-out;

  img {
    margin: 0 auto;
    padding-bottom: 1.5rem;
    width: 14rem;
    height: 14rem;
  }

  h4 {
    padding-bottom: 1rem;
    font-family: Univers, sans-serif;
    text-transform: uppercase;
  }

  h4,
  p {
    font-size: 1.125rem;
    line-height: 1.2;
    text-align: center;
    color: #17191b;
  }
`;

const Card = ({ title, description, image }) => (
  <Container>
    <img src={image} alt={title} />
    <h4>{title}</h4>
    <p>{description}</p>
  </Container>
);

export default Card;
