import "core-js/stable";
import "regenerator-runtime/runtime";

import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { createStore, applyMiddleware } from "redux";
import { Provider } from "react-redux";
import thunk from "redux-thunk";

import reducers from "../reducers";
import GlobalStyle from "../components/theme/global-style";
import Main from "./main";
import Survey from "./survey";

const App = () => {
  const store = createStore(reducers, applyMiddleware(thunk));

  return (
    <Provider {...{ store }}>
      <Router>
        <GlobalStyle />
        <Switch>
          <Route path="/" exact component={Main} />
          <Route path="/:cat(survey)" exact component={Survey} />
        </Switch>
      </Router>
    </Provider>
  );
};

export default App;
