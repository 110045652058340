import "core-js/stable";
import "regenerator-runtime/runtime";
import "promise-polyfill/src/polyfill";

import React from "react";
import ReactDOM from "react-dom";

import "./index.less";
import "mapbox-gl/dist/mapbox-gl.css";
import "react-redux-toastr/lib/css/react-redux-toastr.min.css";
import App from "./pages/app";
import * as serviceWorker from "./serviceWorker";

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
