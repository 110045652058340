import React from "react";
import { Empty, Steps } from "antd";
import styled from "styled-components";

const { Step } = Steps;

const ScrollContainer = styled.div`
  padding-top: 4rem;
  background-color: #e8eef1;
`;
const Container = styled.div`
  position: sticky;
  top: 0;
  padding: 2rem;
  max-height: 100vh;
  max-width: 25rem;
  overflow-y: auto;

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    max-height: 100%;
  }

  @media screen and (max-width: 640px) {
    display: none;
  }

  .ant {
    &-steps {
      &-item {
        &-tail::after {
          background: transparent;
          border: 0.0625rem dashed #8b8c8c;
        }

        &-title {
          margin-top: 0.5rem;
          margin-bottom: 2rem;
          font-size: 1.125rem;
          line-height: 1.2;
        }

        &-icon {
          margin-right: 0.75rem;
          font-size: 0.875rem;
          border: 0.0625rem solid #8b8c8c;
        }

        &-content {
          min-height: 6rem;
        }

        &-process .ant-steps-item-icon {
          background-color: #fff;
        }

        &-wait,
        &-finish {
          .ant-steps-item-icon {
            background-color: transparent;
          }
        }

        &-process,
        &-finish {
          > .ant-steps-item-container
            > .ant-steps-item-content
            > .ant-steps-item-title {
            color: #17191b;
          }
        }

        &-wait {
          .ant-steps-item-icon > .ant-steps-icon {
            color: #8b8c8c;
          }
        }

        &-finish {
          > .ant-steps-item-container > .ant-steps-item-tail::after {
            background-color: transparent;
            border-color: #17191b;
          }

          .ant-steps-item-icon {
            border-color: #17191b;

            > .ant-steps-icon {
              color: #17191b;
            }
          }
        }

        &-process {
          .ant-steps-item-icon > .ant-steps-icon {
            color: #fff;
          }

          .ant-steps-item-icon {
            border-color: #17191b;
            background-color: #17191b;

            > .ant-steps-icon {
              color: #fff;
            }
          }
        }
      }
    }
  }
`;

const StepsSidebar = ({ steps, current }) => (
  <ScrollContainer>
    <Container>
      {steps?.length > 0 ? (
        <Steps direction="vertical" {...{ current }}>
          {steps.map(({ title }, i) => (
            <Step key={i} {...{ title }} />
          ))}
        </Steps>
      ) : (
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="Нет шагов" />
      )}
    </Container>
  </ScrollContainer>
);

export default StepsSidebar;
