import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

import Section from "./section";
import Button from "../buttons/button";
import bgDesktop from "../../images/bg-desktop.svg";
import bgMobile from "../../images/bg-mobile.svg";

const Container = styled(Section)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100vh;
  font-size: 1.75rem;
  line-height: 1.2;
  background: url(${bgDesktop}) #e8eef1 center/contain no-repeat;

  @media screen and (max-width: 640px) {
    background-image: url(${bgMobile});
  }

  > div {
    padding-top: 6rem;
  }
`;
const InnerContainer = styled.div`
  @media screen and (min-width: 641px) {
    grid-column: span 6;
  }
`;
const Title = styled.h1`
  margin-bottom: 3rem;
  font-size: 6rem;
  line-height: 1.05;
  letter-spacing: 0.04em;
  text-transform: uppercase;

  @media screen and (max-width: 640px) {
    font-size: 3rem;
  }
`;
const Lead = styled.p`
  margin-bottom: 4.5rem;
  font-size: 1.875rem;
  line-height: 1.2;

  @media screen and (max-width: 640px) {
    font-size: 1rem;
  }
`;
const ButtonContainer = styled.div`
  display: grid;
  grid-template-columns: min-content min-content;
  grid-gap: 1.5rem;

  @media screen and (max-width: 640px) {
    display: flex;
    flex-direction: column;
    align-items: center;

    a + a {
      display: none;
    }
  }

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    > *:not(:last-child) {
      margin-right: 1.5rem;
    }
  }
`;

const First = ({ page }) => {
  const { title, lead } = page;

  return (
    <Container>
      <InnerContainer>
        <Title>{title}</Title>
        <Lead>{lead}</Lead>
        <ButtonContainer>
          <Link to="/survey">
            <Button type="primary">Пройти опрос</Button>
          </Link>
          <HashLink to="#about" smooth>
            <Button type="secondary">О проекте</Button>
          </HashLink>
        </ButtonContainer>
      </InnerContainer>
    </Container>
  );
};

export default First;
