import React from "react";
import styled from "styled-components";

const PageTitle = styled.h3`
  margin: 0 1rem 3rem;
  color: #ee9173;
`;

const TabHeader = ({ steps, current }) => (
  <PageTitle>{steps?.[current]?.title}</PageTitle>
);

export default TabHeader;
