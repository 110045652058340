import React from "react";
import styled from "styled-components";

const Button = styled(({ type, htmlType, ...props }) => (
  <button type={htmlType} {...props} />
))`
  padding: 1rem 2.25rem;
  background-color: ${({ type }) =>
    type === "primary" ? "#EE9173" : type === "secondary" ? "#D4D8DB" : "#fff"};
  border: 0;
  box-sizing: border-box;
  border-radius: 3rem;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  color: ${({ type }) => (type === "primary" ? "#fff" : "#17191B")};

  transition: all 0.3s ease-in-out;
  appearance: none;

  :hover {
    background-color: ${({ type }) =>
      type === "primary"
        ? "#D68267"
        : type === "secondary"
        ? "#B4B9BB"
        : "#fff"};
  }

  :focus {
    outline: 0;
  }
`;

export default Button;
