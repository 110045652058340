
const _dive = function (tokens, data, dflt=undefined) {

    if (tokens.length === 0)
        return data;

    if (!data)
        return dflt;

    const curKey = tokens.shift();

    if (data.hasOwnProperty(curKey)) {
        return _dive(tokens, data[curKey], dflt)
    }

    return dflt;
};

export const pick = function (route, data, dflt=undefined) {
    const tokenized = route.split('.');
    return _dive(tokenized, data, dflt);
};