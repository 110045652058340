import React from "react";
import { Form, Input } from "antd";
import styled from "styled-components";

const Container = styled.div`
  display: inline-block;

  .ant {
    &-form-item-control-input {
      min-height: 1.5rem;
    }

    &-input {
      margin-left: 0.75rem;

      .ant-form-item-has-error & {
        border-color: #d9d9d9;

        :hover {
          border-color: #fab9a0;
        }

        [disabled] {
          background-color: #f5f5f5;

          :hover {
            border-color: #d9d9d9;
          }
        }
      }
    }

    &-form-item {
      margin-bottom: 0;

      &-label > label {
        color: #8b8c8c;
      }
    }
  }
`;

const OtherField = ({ name, label, disabled, otherValue }) => (
  <Container>
    <Form.Item
      {...{ label }}
      name={`${name}_${otherValue}`}
      rules={[{ type: "string" }]}
    >
      <Input {...{ disabled }} size="small" placeholder="Введите ответ" />
    </Form.Item>
  </Container>
);

export default OtherField;
