import React from "react";
import styled from "styled-components";

const Container = styled.div`
  width: 100%;
`;
const InnerContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  grid-gap: 1.5rem;
  margin: 0 auto;
  padding: 2rem;
  max-width: 54rem;
  width: 100%;

  > * {
    grid-column: span 8;
  }

  @media screen and (max-width: 640px) {
    grid-template-columns: 1fr;
    padding: 2rem 1.5rem;
    max-width: 100%;

    > * {
      grid-column: auto;
    }
  }
`;

const Section = ({ id, className, children }) => (
  <Container {...{ id, className }}>
    <InnerContainer>{children}</InnerContainer>
  </Container>
);

export default Section;
