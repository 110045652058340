import React from "react";
import styled from "styled-components";

import Section from "./section";
import Button from "../buttons/button";
import { Link } from "react-router-dom";

const Container = styled(Section)`
  > div {
    grid-row-gap: 3rem;
    padding-bottom: 10rem;

    @media screen and (max-width: 640px) {
      padding-bottom: 5rem;
    }
  }

  a {
    justify-self: center;
  }

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    > div {
      display: flex;
      flex-direction: column;
    }

    a {
      align-self: center;
    }

    p {
      margin-bottom: 4.5rem;
    }
  }
`;

const Description = () => (
  <Container>
    <p>
      Данный опрос позволит вовлечь муниципалитеты в&nbsp;формирование перечня
      актуальных мер поддержки городов для восстановления городской экономики,
      а&nbsp;его результаты могут быть использованы наряду с другими
      инструментами анализа (статистический, экспертный, и&nbsp;пр.) при отборе
      новых мер поддержки городской экономики.
    </p>
    <Link to="/survey">
      <Button type="primary">Пройти опрос</Button>
    </Link>
  </Container>
);

export default Description;
