import React from "react";
import { Form, Select } from "antd";
import styled from "styled-components";

const { Option } = Select;

const Container = styled.div`
  > .ant-form-item {
    margin-bottom: 0.5rem;
  }
`;

const SelectControl = ({ name, type, options }) => {
  const filterOption = (input, option) =>
    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
    option?.type === "plain";

  const placeholder = `Выберите ответ${type === "checkbox" ? "ы" : ""}`;

  return (
    <Container>
      <Form.Item {...{ name }}>
        <Select
          showSearch
          optionFilterProp="children"
          mode={type === "checkbox" && "multiple"}
          notFoundContent="Нет подходящих ответов"
          {...{ filterOption, placeholder }}
        >
          {options?.map(({ name, value, tags }, i) => (
            <Option key={i} {...{ value, tags }}>
              {name}
            </Option>
          ))}
        </Select>
      </Form.Item>
    </Container>
  );
};

export default SelectControl;
