import React, { useState } from "react";
import { Empty, Form } from "antd";
import styled from "styled-components";

import Controls from "./controls";
import StepsSidebar from "./steps-sidebar";
import { handleFormSteps } from "./utils";
import SurveyForm from "./survey-form";

const Container = styled.div`
  display: flex;
  flex: 1 0 auto;
  width: 100%;

  @media screen and (max-width: 640px) {
    display: flex;
    flex-direction: column;
  }
`;
const Content = styled.div`
  padding: 7rem 2rem 4rem;
  max-width: 40rem;
  width: 100%;

  .ant {
    &-picker {
      width: 100%;
      max-width: 10rem;
    }

    &-tabs-tabpane {
      padding: 0 1rem;
    }
  }

  @media screen and (max-width: 640px) {
    padding: 2rem 0;
  }
`;

const Wizard = ({ data, platformId, onSubmit, isLoading }) => {
  const [form] = Form.useForm();
  const [current, setCurrent] = useState(0);
  const { nodes, sections } = data ?? {};
  const steps = handleFormSteps(sections, nodes);

  const onClick = (i) => setCurrent((current) => current + i);

  return (
    <Container>
      {steps?.length > 1 && <StepsSidebar {...{ steps, current }} />}
      <Content>
        {steps?.length > 0 ? (
          <SurveyForm {...{ form, data, platformId, onSubmit, current }} />
        ) : (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="Нет полей" />
        )}
        <Controls {...{ steps, current, onClick, isLoading, form }} />
      </Content>
    </Container>
  );
};

export default Wizard;
