import React from "react";
import styled from "styled-components";

import icon1 from "../../images/icons/icon1.svg";
import icon2 from "../../images/icons/icon2.svg";
import icon3 from "../../images/icons/icon3.svg";
import icon4 from "../../images/icons/icon4.svg";
import Section from "./section";
import Card from "../card";

const Container = styled(Section)`
  @media screen and (min-width: 641px) {
    > div > * {
      grid-column: span 4;

      @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        margin-bottom: 1.5rem;
      }
    }
  }

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    > div > * {
      margin-bottom: 1.5rem;
    }
  }
`;

const items = [
  {
    image: icon1,
    title: "Оценка влияния кризиса",
    description:
      "Оценка степени влияния текущего кризиса на деловую активность в\xa0городах России",
  },
  {
    image: icon2,
    title: "Сбор актуальных планов",
    description:
      "Сбор и актуализация данных о\xa0существующих планах по\xa0восстановлению городских экономик после текущего кризиса",
  },
  {
    image: icon3,
    title: "Проверка осведомленности",
    description:
      "Подтверждение осведомленности о\xa0существующих мерах поддержки городской экономики, включая меры, предлагаемые ВЭБ.РФ",
  },
  {
    image: icon4,
    title: "Сбор предложений",
    description:
      "Сбор запросов и\xa0предложений городов по\xa0долгосрочным мерам поддержки, необходимым для устойчивого развития городской экономики",
  },
];

const Cards = () => (
  <Container>
    {items.map((item, i) => (
      <Card key={i} {...item} />
    ))}
  </Container>
);

export default Cards;
