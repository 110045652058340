import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

import privacy from "../pdf/privacy_policy_ru.pdf";
import terms from "../pdf/terms_of_use_ru.pdf";
import { ReactComponent as LogoKB } from "../images/icons/logo-kb.svg";
import Section from "./sections/section";

const Container = styled(Section)`
  background-color: #17191b;
  color: #e8eef1;

  > div {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 2rem;
    padding-top: 4.5rem;
    padding-bottom: 6rem;
    max-width: 79rem;

    > * {
      grid-column: inherit;
    }

    @media screen and (max-width: 960px) {
      grid-template-columns: 1fr 1fr;
    }

    @media screen and (max-width: 640px) {
      grid-template-columns: 1fr;
    }
  }

  a,
  p {
    font-size: 1rem;

    @media screen and (max-width: 640px) {
      font-size: 0.75rem;
      line-height: 1.5;
    }
  }
`;
const Logo = styled.div`
  svg path {
    fill: #e8eef1;
  }
`;
const Developer = styled.div`
  a {
    white-space: nowrap;
  }
`;
const Documents = styled.div`
  display: flex;
  flex-direction: column;
`;

const Footer = () => (
  <footer>
    <Container>
      <Logo>
        <a href="https://strelka-kb.com/">
          <LogoKB />
        </a>
      </Logo>
      <Developer>
        <p>
          <span>Разработано на платформе </span>
          <a href="https://чего-хочет-город.рф/">Чего-хочет-город.рф</a>
        </p>
        <p>© ООО «КБ Стрелка», 2020</p>
      </Developer>
      <Documents>
        <Link to={terms} target="_blank" download>
          Пользовательское соглашение
        </Link>
        <Link to={privacy} target="_blank" download>
          Положение об обработке и защите персональных данных
        </Link>
      </Documents>
    </Container>
  </footer>
);

export default Footer;
