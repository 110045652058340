import React from "react";
import styled from "styled-components";
import { LoadingOutlined } from "@ant-design/icons";

import Button from "../buttons/button";

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 1rem;

  button {
    :not(:last-child) {
      margin-right: 1rem;
    }

    :last-child {
      margin-left: auto;
    }
  }
`;

const Controls = ({ steps, current, onClick, isLoading, form }) => {
  const currentNodes = steps?.[current]?.fields;
  const otherFields = currentNodes
    ?.filter(({ answers }) => answers?.some(({ type }) => type === "plain"))
    .map(
      ({ node, answers }) =>
        `${node}_${answers?.filter(({ type }) => type === "plain")?.[0]?.value}`
    );
  const currentFields = [
    ...currentNodes?.map(({ node }) => node),
    ...otherFields,
  ];

  const validateFields = (fields, callback) =>
    form
      .validateFields(fields)
      .then((values) => values && callback())
      .catch(() => console.warn("Заполните обязательные поля!"));
  const prev = () => onClick(-1);
  const next = () =>
    validateFields(currentFields, () => {
      onClick(1);
      typeof window !== undefined && window.scrollTo(0, 0);
    });
  const buttons = [
    {
      children: "Назад",
      type: "secondary",
      onClick: () => prev(),
      condition: current > 0,
    },
    {
      children: "Вперёд",
      type: "primary",
      onClick: () => next(),
      condition: current < steps.length - 1,
    },
    {
      children: isLoading ? <LoadingOutlined spin /> : "Отправить",
      type: "primary",
      htmlType: "submit",
      onClick: () => form.submit(),
      condition: current === steps.length - 1,
      form: "survey",
    },
  ];
  const handleButtons = (buttons) =>
    buttons.map(
      ({ condition, ...rest }, i) => condition && <Button key={i} {...rest} />
    );

  return <Container>{handleButtons(buttons)}</Container>;
};

export default Controls;
