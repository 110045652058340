import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Result } from "antd";
import styled from "styled-components";

import { project } from "../config";
import { getAppData, getDialogData, postDialog } from "../actions/app";
import Meta from "../components/meta";
import Header from "../components/header";
import Wizard from "../components/wizard";
import Button from "../components/buttons/button";
import Footer from "../components/footer";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  height: 100vh;
`;
const InnerContainer = styled.div`
  align-items: center;

  &,
  .ant-result {
    display: flex;
    flex: 1 0 auto;
  }

  .ant-result {
    flex-direction: column;
    justify-content: center;
    padding-top: 6rem;
    height: 100%;
  }
`;

const Survey = () => {
  const { page, projectId, dialog } = useSelector(({ app }) => app);
  const { thanks } = page;
  const dispatch = useDispatch();
  const data = dialog?.length > 0 && dialog[0];
  const platformId = 1;
  const [isSuccess, setIsSuccess] = useState(true);
  const [error, setError] = useState({ isError: false, message: "" });
  const { isError, message } = error;
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    dispatch(getAppData(project)).catch(() =>
      setError({ isError: true, message: "Проект не найден!" })
    );
    projectId !== "" &&
      dispatch(getDialogData(projectId, platformId)).catch(() =>
        setError({ isError: true, message: "Опрос не найден!" })
      );
  }, [dispatch, projectId]);

  const onSubmit = (data) => {
    setIsLoading(true);
    dispatch(postDialog(data))
      .then(() => setIsSuccess(true))
      .catch(() => console.warn("Ошибка при отправке формы!"));
  };

  return (
    <Container>
      <Meta title="Пройдите опрос" {...{ page }} />
      <Header shadow />
      <InnerContainer>
        {isSuccess ? (
          <Result
            status="success"
            title="Отправлено!"
            subTitle={thanks ? thanks : "Спасибо за ваше участие!"}
            extra={[
              <Link to="/" key="home">
                <Button type="primary">На главную</Button>
              </Link>,
            ]}
          />
        ) : isError ? (
          <Result
            status="error"
            title={message}
            extra={[
              <Link to="/" key="home">
                <Button type="primary">На главную</Button>
              </Link>,
            ]}
          />
        ) : (
          data && <Wizard {...{ data, platformId, onSubmit, isLoading }} />
        )}
      </InnerContainer>
      <Footer />
    </Container>
  );
};

export default Survey;
