import React, { useState } from "react";
import { Checkbox, Form, Radio } from "antd";
import styled from "styled-components";

import OtherField from "./other-field";

const Container = styled.div`
  .ant {
    &-form-item {
      margin-bottom: 0;
    }

    &-radio,
    &-checkbox {
      align-self: flex-start;
      margin-top: 0.0625rem;

      &-group {
        display: flex;
        flex-direction: column;
      }

      &-wrapper {
        display: flex;
        margin-left: 0;
        margin-bottom: 0.5rem;
        font-size: 1rem;
        line-height: 1.25;
        white-space: normal;

        > span {
          display: inline-flex;
        }
      }
    }
  }
`;

const ControlGroup = ({ name, type, options }) => {
  const [innerValue, setInnerValue] = useState([]);
  const isRadio = type === "radio";
  const Component = isRadio ? Radio : Checkbox;
  const compareStrOrArr = (a, b, isStr) => (isStr ? a === b : a.includes(b));
  const handleOtherValue = (answers) =>
    answers.filter(({ type }) => type === "plain")?.[0]?.value;
  const otherValue = handleOtherValue(options);
  const otherField = `${name}_${otherValue}`;
  const rules = otherValue && [
    ({ getFieldValue }) => ({
      validator: (rule, value) => {
        const isOther = isRadio
          ? value === otherValue
          : value?.includes(otherValue);

        const isNotValid =
          getFieldValue(otherField) === undefined ||
          getFieldValue(otherField) === "";

        return isOther && isNotValid
          ? Promise.reject("Заполните поле «Другое»!")
          : Promise.resolve();
      },
      validateTrigger: "onSubmit",
    }),
  ];
  return (
    <Container>
      <Form.Item
        {...{ name, rules }}
        dependencies={otherValue ? [otherField] : []}
      >
        <Component.Group
          onChange={(e) => setInnerValue(isRadio ? e?.target?.value : e)}
        >
          {options?.map(({ name: label, value, type }, i) => (
            <Component key={i} {...{ value }}>
              {label}
              {type === "plain" && (
                <OtherField
                  {...{ name }}
                  otherValue={value}
                  disabled={!compareStrOrArr(innerValue, value, isRadio)}
                />
              )}
            </Component>
          ))}
        </Component.Group>
      </Form.Item>
    </Container>
  );
};

export default ControlGroup;
