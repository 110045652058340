import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import Button from "./buttons/button";

const Container = styled.header`
  position: ${({ fixed }) => (fixed ? "fixed" : "absolute")};
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
  width: 100%;
  height: 4rem;
  padding: 0.75rem 2rem;
  font-size: 0.875rem;
  font-weight: 500;
  background-color: ${({ transparent }) =>
    transparent ? "transparent" : "#fff"};
  box-shadow: ${({ transparent }) =>
    !transparent && "0 0.125rem 0.5rem rgba(0, 0, 0, 0.12)"};
  transition: all 0.15s ease-in-out;

  @media screen and (max-width: 640px) {
    padding: 0.75rem 1rem;
  }

  nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;

    > *:not(:last-child) {
      margin-right: 1.5rem;
    }
  }

  a {
    color: ${({ transparent }) => (transparent ? "#fff" : "#17191B")};
    text-shadow: ${({ transparent }) =>
      transparent && "0 0 1.5rem rgba(0, 0, 0, 0.2)"};

    :hover {
      color: ${({ transparent }) =>
        transparent ? "rgba(255, 255, 255, 0.7)" : "#EE9173"};
    }
  }

  ${Button} {
    height: 2.5rem;
    padding: 0.5rem 1rem;
    font-size: 0.75rem;
  }
`;

const Logo = styled.span`
  font-family: Fugue, sans-serif;
  font-weight: 700;
  text-transform: uppercase;
`;

const Header = ({ fixed, animated }) => {
  const { page } = useSelector(({ app }) => app);
  const { title } = page;
  const { cat } = useParams();
  const [isFixed, setIsFixed] = useState(
    animated ? window.pageYOffset > 0 : fixed
  );

  const handleScroll = () => {
    animated && setIsFixed(window.pageYOffset > 0);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  });

  return (
    <Container
      fixed={animated ? isFixed : fixed}
      transparent={fixed && !isFixed}
    >
      <nav>
        <Link to="/">
          <Logo>{title}</Logo>
        </Link>
        {!cat && (
          <Link to="/survey">
            <Button type="primary">Пройти опрос</Button>
          </Link>
        )}
      </nav>
    </Container>
  );
};

export default Header;
