import React, { useState } from "react";
import { Form, Modal, Upload, message } from "antd";
import { PlusCircleOutlined } from "@ant-design/icons";
import locale from "antd/es/locale/ru_RU";

const PhotoUpload = props => {
  const [state, setState] = useState({
    previewVisible: false,
    previewImage: "",
    previewTitle: "",
    fileList: []
  });
  const { previewVisible, previewImage, previewTitle, fileList } = state;

  const getBase64 = file =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });

  const customRequest = ({ onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  const handleError = error => message.error(error, 5);

  const handleCheck = file => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      handleError("Вы можете загрузить только JPG/PNG/GIF файл!");
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      handleError(`Размер изображения ${file.name} превышает 2 Мб!`);
    }
    return isJpgOrPng && isLt2M;
  };

  const handleFileList = fileList => {
    if (fileList.length > 5) {
      handleError("Можно загрузить не более 5 изображений!");
    }

    return fileList.filter(file => handleCheck(file)).slice(-5);
  };

  const normFile = e => {
    if (Array.isArray(e)) {
      return handleFileList(e);
    }

    return e && handleFileList(e.fileList);
  };

  const handleChange = ({ fileList }) => {
    setState(state => ({
      ...state,
      fileList: [...fileList]
    }));
  };

  const handleCancel = () =>
    setState(state => ({ ...state, previewVisible: false }));

  const handlePreview = async file => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    setState(state => ({
      ...state,
      previewImage: file.url || file.preview,
      previewVisible: true,
      previewTitle:
        file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
    }));
  };

  return (
    <>
      <Form.Item
        {...props}
        valuePropName="fileList"
        getValueFromEvent={normFile}
        noStyle
      >
        <Upload
          {...{ customRequest, locale }}
          onChange={handleChange}
          listType="picture-card"
          fileList={[...fileList]}
          defaultFileList={[...fileList]}
          previewFile={file => getBase64(file)}
          onPreview={handlePreview}
          accept="image/jpeg, image/png, image/gif"
          multiple
        >
          {fileList.length < 5 && (
            <span>
              <PlusCircleOutlined />
              Прикрепить фото (не более 2 Мб)
            </span>
          )}
        </Upload>
      </Form.Item>
      <Modal
        visible={previewVisible}
        title={previewTitle}
        footer={null}
        onCancel={handleCancel}
      >
        <img alt="example" style={{ width: "100%" }} src={previewImage} />
      </Modal>
    </>
  );
};

export default PhotoUpload;
