/**
 * Created by root on 04.08.2019.
 */

import jwt from 'jsonwebtoken';
import { appConfig } from '../config';
import Env from 'exenv'
// import Cookies from 'js-cookie'

const storageInstance = Env.canUseDOM ? localStorage : {};
const tokenKey = 'access_token';
const lastLoginKey = 'last_login';
const refreshTokenKey = 'refresh_token';

export default class Tokenizer {
    static authHeader(token) {
        return 'Bearer ' + token;
    }

    static decode(token) {
        return jwt.decode(token);
    }

    static isExpired(token) {
        const decoded = Tokenizer.decode(token);
        const currentTs = new Date().getTime() / 1000;

        return decoded['exp'] - currentTs <= 0;
    }

    static get(variable) {
        return storageInstance[variable];
    }

    static verify(token) {
        try {
            return jwt.verify(token, appConfig.auth.secret);
        } catch (e) {
            return false;
        }
    }

    static lastLogin(defaultValue = null) {
        return lastLoginKey in storageInstance ? storageInstance[lastLoginKey] : defaultValue;
    }

    static store(token, refresh_token = null, login = null) {
        storageInstance[tokenKey] = token;
        if (login) {
            storageInstance[lastLoginKey] = login;
        }
        if (refresh_token) {
            storageInstance[refreshTokenKey] = refresh_token;
        }
    }

    static isStored() {
        return storageInstance[tokenKey] !== undefined && storageInstance[tokenKey] != null;
    }

    static load() {
        if (Tokenizer.isStored()) {
            return storageInstance[tokenKey];
        }

        return undefined;
    }

    static clear() {
        storageInstance[tokenKey] = null;
    }
}
