/**
 * External Dependencies
 */
import { combineReducers } from "redux";
import { reducer as toastrReducer } from "react-redux-toastr";

import AppReducer from "./app";
// import Cookies from 'js-cookie';
// import Omit from 'object.omit';

/**
 * Internal Dependencies
 */
import {
  UPDATE_SETTINGS
  // ADD_TOAST,
  // REMOVE_TOAST,
} from "../actions";

// initial state.
const INITIAL_SETTINGS_STATE = {};
const INITIAL_AUTH_STATE = {};

// const INITIAL_TOASTS_STATE = [];

/**
 * Reducer
 */
const rootReducer = combineReducers({
  app: AppReducer,
  auth: (state = INITIAL_AUTH_STATE, action) => {
    switch (action.type) {
      default:
        return state;
    }
  },
  settings: (state = INITIAL_SETTINGS_STATE, action) => {
    switch (action.type) {
      case UPDATE_SETTINGS:
        return Object.assign({}, state, action.settings);
      default:
        return state;
    }
  },
  toastr: toastrReducer
});

export default rootReducer;
