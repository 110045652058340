import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
  }

  body {
    margin: auto;
    color: #17191B;
    font-family: Univers, sans-serif;
  }
  
  h1, h2, h3, h4, h5, h6 {
    font-family: Fugue, sans-serif;
    letter-spacing: 0.04em;
    text-transform: uppercase;
  }
  
  h1 {
    font-size: 6rem;
    font-weight: 700;
  }
  
  h2 {
    margin-top: 160px;
    margin-bottom: 60px;
    font-size: 48px;
    font-weight: 700;
  }
  
  p {
    font-size: 1.5rem;
    line-height: 1.26;
    letter-spacing: -0.005em;
  }
  
  .text-center {
    text-align: center;
  }

  .button-wrapper-centered {
    justify-content: center;
  } 
  
  .ant{
    &icon svg {
      display: block;
    }
    
    &-message-custom-content {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  @media screen and (max-width: 640px) {
    h1 {
      font-size: 36px;
    }

    p {
      font-size: 1rem;
    }
  
    .screen-first {
      padding: 20px;
      text-align: center;
    }
  
    .button {
      font-size: 12px;
    }
  
    .button-wrapper {
      flex-wrap: wrap;
      justify-content: center;
    }
  
    .button-wrapper .button {
      margin-bottom: 10px;
      margin-right: 0;
    }
  
    .idea {
      max-width: 300px;
    }
  
    .idea-wrapper {
      justify-content: center;
    }
  
    .footer-info {
      flex-wrap: wrap;
    }
  
    .social-link img {
      margin-right: 20px;
    }
  }
`;

export default GlobalStyle;
