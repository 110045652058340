import React, { useEffect } from "react";
import { Form, Tabs } from "antd";
import { v1 as uuidv1 } from "uuid";

import Page from "./page";
import TabHeader from "./tab-header";
import { useLocalStorage } from "../../utils/hooks";
import { handleFormData, handleFormSteps, handleInitialValues } from "./utils";

const { TabPane } = Tabs;

const SurveyForm = ({
  form,
  data,
  platformId: platform_id,
  onSubmit,
  current,
}) => {
  const [localId, setLocalId] = useLocalStorage("msp-user-id", undefined);
  const { id, name, nodes, sections } = data ?? {};
  const steps = handleFormSteps(sections, nodes);
  const validateMessages = { required: "Обязательное поле!" };
  const bot = {
    id,
    name,
    platform_id,
  };
  const uuidTemplate = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i;

  useEffect(() => {
    (!localId || !uuidTemplate.test(localId)) && setLocalId(uuidv1());
  }, [localId, setLocalId, uuidTemplate]);

  const onFinish = (data) =>
    onSubmit(handleFormData(data, bot, localId, nodes, sections));

  return (
    <Form
      id="survey"
      layout="vertical"
      initialValues={handleInitialValues(nodes)}
      hideRequiredMark
      {...{
        form,
        onFinish,
        validateMessages,
      }}
    >
      <Tabs
        activeKey={current.toString()}
        animated={false}
        defaultActiveKey={1}
        renderTabBar={() => <TabHeader {...{ steps, current }} />}
        forceRender
      >
        {steps?.map(({ title, fields }, i) => (
          <TabPane key={i} tab={title}>
            <Page {...{ fields }} />
          </TabPane>
        ))}
      </Tabs>
    </Form>
  );
};

export default SurveyForm;
